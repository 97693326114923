<template>
<div class="signup-outer">

    <SideBar />

    <div class="signup-wrapper">
        <div class="signup-top-pane flex align-center">
            <h2>Create New Admin Account</h2>
        </div>
        <div class="admin-signup flex center" id="admin-signup">
            <form class="flex signup-form" id="signup-form" autocomplete="false" @submit.prevent="signup" >

                <h2 class="sub-headings">
                    Admin <span>Signup</span>
                </h2>

                <div class="form-element">
                    <input
                        type="text" data-name="username" data-len="6"
                        id="s-username" class="form-input" autocomplete="false"
                        placeholder="Username" v-model="sUsername"
                        @input="handleInput" @blur="validateFields">
                </div>

                <div class="form-element">
                    <input
                        type="password" data-name="password" data-len="6"
                        id="s-password" class="form-input password" autocomplete="false"
                        placeholder="Password" v-model="sPassword"
                        @input="handleInput" @blur="validateFields">
                </div>

                <div class="form-element c-p-outer">
                    <input
                        type="password" data-name="confirm password" data-len="6" autocomplete="false"
                        id="s-confirm-password" class="form-input cnfrm-pass"
                        placeholder="Confirm Password" v-model="sConfirmPassword"
                        @input="handleInput" @blur="validateFields">
                </div>

                <div class="form-row flex btns-row">
                    <button type="submit" class="btn signup-btn fancy-btn">
                        REGISTER
                    </button>
                    <button type="reset" class="btn reset-btn fancy-btn">
                        RESET
                    </button>
                </div>
            </form>

            <img src="../../assets/Signup side object.png" alt="boy with a phone" class="signup-img">
        </div>
    </div>

</div>
</template>


<script>
import SideBar from '@/components/admin/SideBar.vue';
import { Admin } from '@/services/Service.js'
import $ from 'jquery';

export default {
    data() {
        return {
            sUsername: '',
            sPassword: '',
            sConfirmPassword: ''
        }
    },
    components: {
        SideBar
    },
    methods: {
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields( {target: el} )
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} digits.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        async signup()
        {
            if( this.sUsername === '' )
                this.addErrorMessage('s-username');
            else if( this.sUsername.length < 6 )
                this.addErrorMessage('s-username', 'username must have atleast 6 chars.' );

            if( this.sPassword === '' )
                this.addErrorMessage('s-password');
            else if( this.sPassword.length < 6 )
                this.addErrorMessage('s-password','password must have atleast 6 chars.');
            else if( this.sConfirmPassword === '' )
                this.addErrorMessage('s-confirm-password');
            else if( this.sConfirmPassword.length < 6 )
                this.addErrorMessage('s-confirm-password','confirm username must have atleast 6 chars.');
            else if( $('#s-password').val() !== $('#s-confirm-password').val() )
                this.addErrorMessage('s-confirm-password','Passwords doesnot match.')

            if( $('#signup-form .error-msg').length ) return;
            $('.signup-btn').blur();
            $('.signup-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Admin.signup({
                    username: this.sUsername,
                    password: this.sPassword
                });
                if( response.status === 200 )
                {
                    $('.signup-btn').html(`Signup`);
                    const notification = {
                        type: 'success',
                        message: 'Signup successfull. You can login with that account from next time.!.'
                    }
                    this.$store.dispatch('addNotification', notification);

                    this.sUsername = '';
                    this.sPassword = '';
                    this.sConfirmPassword = '';

                    this.$router.push({ name: 'profile' });
                }
            } catch (error)
            {
                const { message } = error.response.data;
                const notification = { type: 'error', message };

                if( message === 'User already registered.') this.sUsername = '';

                $('.signup-btn').html(`Signup`);
                this.$store.dispatch('addNotification', notification);
            }
        }
    },
    watch: {
        sPassword(val) {
            if( this.sConfirmPassword === val )
                this.removeErrorMessage('s-confirm-password');
            else if( this.sConfirmPassword !== '' )
                this.addErrorMessage('s-confirm-password', 'Passwords doesnot match.');
        },
        sConfirmPassword(val) {
            if( this.sPassword !== val )
                this.addErrorMessage('s-confirm-password', 'Passwords doesnot match.');
            else
                this.removeErrorMessage('s-confirm-password');
        }
    },
    created () {
        this.sUsername = '';
        this.sConfirmPassword = '';
        this.sPassword = '';
    },
}
</script>

<style scoped>

.signup-wrapper
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
}

.signup-top-pane
{
    height: 80px;
    background-color: #fff;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
}

h2
{
    font-weight: 800;
    font-size: 26px;
}

.admin-signup
{
    height: calc(100vh - 80px);
}

form { flex-direction: column; }

form>* { margin-bottom: 24px; }

form>*:last-child { margin-bottom: 0; }

form h2 { margin-bottom: 50px; }

.form-input
{
    height: 50px; width: 350px;
    border: 2px solid #E20613;
    background-color: rgb(0, 0, 0,0.7);
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.form-input::placeholder { color: #f7f6e7; }

.form-input:focus { border: 2px solid white; }

.btn
{
    height: 50px; width: 350px;
    border-radius: 3px;
    box-shadow: 0px 0px 6px rgb(255, 255, 255,0.2);
    transition: all 0.1s ease;
    border: 2px solid transparent;
    background-color: #E20613;
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
}

/* Signup */

.admin-signup .form-input
{
    border: 2.5px solid #000;
    background-color: white;
    box-shadow: 3px 3px 4px rgb(0, 0, 0,0.15);
    color: #000;
}

.admin-signup .form-input::placeholder { color: #000; }

.admin-signup .form-input:focus { border: 2.5px solid #E20613; }

.s-otp-outer, .verify-btn, .reset-btn
{ margin-left: 20px; }

.otp-box { width: 165px; }

.signup-btn, .reset-btn
{
    width: 165px;
    box-shadow: 3px 3px 4px rgb(0, 0, 0,0.15);
    border: none;
}

.reset-btn, .signup-btn::after { background-color: #000; }

.reset-btn::after { background-color: #E20613; }

.admin-signup .container
{
    position: relative;
    justify-content: space-evenly;
    align-items: center;
}

.signup-img { height: 500px; margin-left: 150px; }

@media (max-width: 1023px)
{
    .signup-img { display: none; }
}

@media (max-width: 767px)
{
    .admin-signup form { align-items: center; }

    .admin-signup .form-row { width: 350px; }

    .otp-btn { width: 100%; }

    .s-otp-outer { margin: 0; }

    .otp-box, .otp-btn, .verify-btn { margin: 20px 0 0 !important; }

    .verify-btn { margin-left: 20px !important; }
}

@media (max-width: 480px)
{
    .login-form
    {
        width: 100%;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    form, .form-input, .admin-signup .form-row, .sp-row,
    .login-btn, .link-text, .form-element { width: 100%; }

    .signup-btn, .reset-btn, .otp-box, .verify-btn
    { width: calc(50% - 10px) !important; }
}

</style>